import React from "react"
import { graphql, Link } from "gatsby"
import statWinner from "../../js/statWinner"
import percentageOf from "../../js/percentageOf"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class PenaltiesPg extends React.Component {

  state = {
    showTopLevel: false,
  };

  manageTopLevelToggle = () => {
    this.setState(state => ({ showTopLevel: !state.showTopLevel }));
  };

  render() {

    const pageTitle = "Penalties"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.pens)[0]) * ( 100 / (parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.pens)[0]) + parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.pensMissed)[0]) ))).toFixed(1)
      ) : (
        parseFloat(parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.pens)[0]) * ( 100 / (parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.pens)[0]) + parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.pensMissed)[0]) ))).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "All Time Career",
        "mSubStat": subStat('m', 'All Time Career'),
        "rSubStat": subStat('r', 'All Time Career'),
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Career Europe",
        "title": "All Time Career *",
        "mSubStat": subStat('m', 'All Time Career Europe'),
        "rSubStat": subStat('r', 'All Time Career Europe'),
        "btnText": "Yearly Breakdown",
        "topLevel": true
      },
      {
        "comp": "All Time Club",
        "mSubStat": subStat('m', 'All Time Club'),
        "rSubStat": subStat('r', 'All Time Club'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Club Europe",
        "title": "All Time Club *",
        "mSubStat": subStat('m', 'All Time Club Europe'),
        "rSubStat": subStat('r', 'All Time Club Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "League",
        "mSubStat": subStat('m', 'All Time League'),
        "rSubStat": subStat('r', 'All Time League'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time League Europe",
        "title": "League *",
        "mSubStat": subStat('m', 'All Time League Europe'),
        "rSubStat": subStat('r', 'All Time League Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'All Time Europe'),
        "rSubStat": subStat('r', 'All Time Europe'),
        "btnText": "Season Breakdown",
        "topLevel": "both"
      },
      {
        "comp": "Other Club Cups",
        "mSubStat": subStat('m', 'All Time Cups'),
        "rSubStat": subStat('r', 'All Time Cups'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Cups Europe",
        "title": "Other Club Cups *",
        "mSubStat": subStat('m', 'All Time Cups Europe'),
        "rSubStat": subStat('r', 'All Time Cups Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "International",
        "mSubStat": subStat('m', 'All Time Internationals'),
        "rSubStat": subStat('r', 'All Time Internationals'),
        "btnText": "Yearly Breakdown",
        "topLevel": "both"
      },
    ]

    let [{
      pens: mAllTimePens,
      pensMissed: mAllTimePensMissed,
      goals: mAllTimeGoals,
      hatTricks: mAllTimeHatTricks
    }] = this.props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Career").map(d => d.node)
  
    let [{
      pens: rAllTimePens,
      pensMissed: rAllTimePensMissed,
      goals: rAllTimeGoals,
      hatTricks: rAllTimeHatTricks
    }] = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Career").map(d => d.node)

    const mHTdata = this.props.data.allSheetMessiHatTricks.edges
    const rHTdata = this.props.data.allSheetRonaldoHatTricks.edges

    // Number of hat-tricks that needed a penalty goal
    const mHTPens = mHTdata.filter(d => (d.node.goals-d.node.pens) >= 3).length
    const rHTPens = rHTdata.filter(d => (d.node.goals-d.node.pens) >= 3).length

    return (

      <Layout>
        <SEO
          title={`Penalties - Messi and Ronaldo All Time Penalty Goals, Misses and Penalty Conversion Rates`}
          description={`Compare Messi and Ronaldo's penalty records as we detail their penalties scored, penalties missed and the resulting penalty conversion rates.`}
          canonicalPath={`/detailed-stats/penalties/`}
        />

        <h1>Penalties <span className="sr-only">Messi and Ronaldo All Time Penalty Stats</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-2" onChange={this.manageTopLevelToggle}>
          <label className={`flex items-center cursor-pointer px-3 sm:px-5 pt-3 pb-4 bg-noir-lighten10 border ${this.state.showTopLevel ? 'border-highlight' : 'border-noir-lighten10'}`}>
            <input type="checkbox" checked={this.state.showTopLevel ? true : false} />
            <span className="ml-2 text-xs sm:text-sm">Only include club stats for European clubs</span>
          </label>
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              title={d.title ? d.title : d.comp}
              topLevel={d.topLevel}
              showTopLevel={this.state.showTopLevel}
              playerTitleHidden={`${d.comp} ${pageTitle}`}
              statType={pageTitle}
              btnText={d.btnText}
              statView={['Totals', "Penalty Conversion %", "Pens as % of Total Goals"]}
              mSubStat={`scores ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'}% of penalties`}
              rSubStat={`scores ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'}% of penalties`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-')}
            />
          ))}

        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo Penalty Record</h2>

          <InfoBar />

          <p>Ronaldo is in the ascendency when it comes to penalties. Excluding shootouts, <strong className="text-ronaldo">Ronaldo’s overall penalty conversion rate is {Math.round(parseFloat(rAllTimePens) * (100 / (parseFloat(rAllTimePens) + parseFloat(rAllTimePensMissed))))}%</strong>, and <strong className="text-messi">Messi's overall penalty conversion rate is {Math.round(parseFloat(mAllTimePens) * (100 / (parseFloat(mAllTimePens) + parseFloat(mAllTimePensMissed))))}%</strong>.</p>

          <p><strong className="text-ronaldo">Ronaldo has scored a staggering {rAllTimePens} penalties (with {rAllTimePensMissed} misses)</strong> over his whole career, whereas <strong className="text-messi">Messi has scored {mAllTimePens} penalties (with {mAllTimePensMissed} misses)</strong> - again excluding shootouts. It should be noted however that one of Messi’s “misses” was a deliberate assist for a Luis Suarez goal.</p>

          <p>Ronaldo's stats are clearly superior, however he's not necessarily the number one ice-cool penalty king that the media often portray him to be. His penalty record is fantastic, but there are other elite strikers of the same generation who have better conversion rates, such as Lewandowski (91%), Harry Kane (86%) and Ibrahimovic (85%).</p>

          <h3>Big Penalty Misses</h3>
          <p>Both players have missed penalties in big games over their careers, sometimes costing their team and sometimes not.</p>

          <h4>Messi's Big Penalty Misses</h4>
          <ul>
            <li><span role="img" aria-label="red cross">❌</span> 2012 Champions League Semi-final vs Chelsea (lost)</li>
            <li><span role="img" aria-label="red cross">❌</span> 2016 Copa America Final vs Chile (penalty shootout) (lost)</li>
          </ul>

          <h4>Ronaldo's Big Penalty Misses</h4>
          <ul>
            <li><span role="img" aria-label="red cross">❌</span> 2008 Champions League Semi-final vs Barcelona (won)</li>
            <li><span role="img" aria-label="red cross">❌</span> 2008 Champions League Final vs Chelsea (penalty shootout) (won)</li>
            <li><span role="img" aria-label="red cross">❌</span> 2012 Champions League Semi-final vs Bayern Munich (penalty shootout) (lost)</li>
          </ul>

          <h3>Penalty Shootout Records</h3>
          <p>In penalty shootouts, <strong className="text-messi">Messi has scored in 11 out of 13 shootouts (84.6%)</strong>, and <strong className="text-ronaldo">Ronaldo has scored in 11 out of 13 penalty shootouts (84.6%)</strong>. Ronaldo has also been on the pitch for a further 4 shootouts in which he didn't get the chance to take one due to being scheduled as the 4th or 5th penalty taker.</p>

          <h4>Messi Penalty Shootout Record</h4>
          <ul>
            <li><span role="img" aria-label="checkmark">✅</span> Copa America QF 2011 vs Uruguay <small>- scored (lost 5-4) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> World Cup SF 2014 vs Netherlands <small>- scored (won 4-2) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Copa America QF 2015 vs Colombia <small>- scored (won 5-4) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Copa America F 2015 vs Chile <small>- scored (lost 4-1) 1st taker</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Copa America F 2016 vs Chile <small>- missed (lost 4-2) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Copa America SF 2021 vs Colombia <small>- scored (won 3-2) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Coupe de France R16 2022 vs Nice <small>- scored (lost 6-5) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> World Cup QF 2022 vs Netherlands <small>- scored (won 4-3) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> World Cup F 2022 vs France <small>- scored (won 4-2) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Leagues Cup R16 2023 vs FC Dallas <small>- scored (won 5-3) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Leagues Cup F 2023 vs Nashville <small>- scored (won 10-9) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> US Open Cup SF 2023 vs Cincinnati <small>- scored (won 5-4) 1st taker</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Copa America QF 2024 vs Ecuador <small>- missed (won 4-2) 1st taker</small></li>
          </ul>

          <p>Won 9 - Lost 4</p>

          <h4>Ronaldo Penalty Shootout Record</h4>
          <ul>
            <li><span role="img" aria-label="checkmark">✅</span> Euro 2004 QF vs England <small>- scored (won 6-5) 4th taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> FA Cup Final 2005 vs Arsenal <small>- scored (lost 5-4) 3rd taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> World Cup QF 2006 vs England <small>- scored (won 3-1) 5th taker</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Champions League Final 2008 vs Chelsea <small>- missed (won 6-5) 3rd taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> EFL Cup Final 2009 vs Spurs <small>- scored (won 4-1) 3rd taker</small></li>
            <li><span role="img" aria-label="red cross">❌</span> Champions League SF 2012 vs Bayern <small>- missed (lost 3-1) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Champions League Final 2016 vs Atletico <small>- scored (won 5-3) 5th taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Euro 2016 QF vs Poland <small>- scored (won 5-3) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> FA Cup 2022 R32 vs Middlesbrough <small>- scored (lost 8-7) 4th taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Asian Champions League QF 2024 vs Al-Ain <small>- scored (lost 1-3) 3rd taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Saudi King Cup Final 2024 vs Al Hilal <small>- scored (lost 4-5) 2nd taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Euro 2016 R16 vs Slovenia <small>- scored (won 3-0) 1st taker</small></li>
            <li><span role="img" aria-label="checkmark">✅</span> Euro 2016 QF vs France <small>- scored (lost 3-5) 1st taker</small></li>
          </ul>
          
          <ul>
            <li>FA Community Shield Final 2007 vs Chelsea - (won 3-0) scheduled 4th/5th taker</li>
            <li>Euro 2012 SF vs Spain - (lost 4-2) scheduled 5th taker</li>
            <li>Confederations Cup SF 2017 vs Chile - (lost 3-0) scheduled 5th taker</li>
            <li>Coppa Italia Final 2020 vs Napoli - (lost 4-2) scheduled 5th taker</li>
          </ul>

          <p>Won 8 - Lost 9</p>

          <h3>How much do they rely on penalties?</h3>

          <p>If we look at what proportion penalty goals make up of their overall goal tallies, it's Ronaldo who has a higher percentage of penalty goals to non-penalty goals.</p>

          <p>Over his whole career, <strong className="text-ronaldo">{percentageOf(rAllTimePens, rAllTimeGoals)}% of Ronaldo's goals have been penalties</strong> ({rAllTimePens}/{rAllTimeGoals}). In comparison, <strong className="text-messi">{percentageOf(mAllTimePens, mAllTimeGoals)}% of Messi's career goals have been penalties</strong> ({mAllTimePens}/{mAllTimeGoals}).</p>

          <p>It's a similar story when it comes to the reliance on penalty goals when scoring hat-tricks. {rAllTimeHatTricks-rHTPens} of Ronaldo's {rAllTimeHatTricks} career hat-tricks <strong className="text-ronaldo">({parseFloat((Number(rAllTimeHatTricks-rHTPens) / Number(rAllTimeHatTricks)) * 100).toFixed(1)}%) have included a penalty goal</strong>, whereas {mAllTimeHatTricks-mHTPens} of Messi's {mAllTimeHatTricks} career hat-tricks <strong className="text-messi">({parseFloat((Number(mAllTimeHatTricks-mHTPens) / Number(mAllTimeHatTricks)) * 100).toFixed(1)}%) have included a penalty goal</strong>.</p>

          <h3>Who takes more penalties?</h3>

          <p>In terms of penalty attempts, Ronaldo has taken <strong className="text-ronaldo">{(Number(rAllTimePens)+Number(rAllTimePensMissed))-(Number(mAllTimePens)+Number(mAllTimePensMissed))} more in-game penalties</strong> than Messi, partly because he's played two more years than Messi, and partly because Messi periodically allows his team mates to take penalties.</p>

          <p>The most penalties Messi has taken in a single club season is 17 in 2011/12, scoring 14 - which is also the most he has scored in a single club season.</p>
          
          <p>The most penalties Ronaldo has taken in a club season is 16 in 2014/15 and 2019/20, scoring 13. However, the most penalties Ronaldo has <em>scored</em> in a club season is 14 in 2011/12 (out of 15 penalty attempts), and 14 in 2019/20 (out of 16).</p>

          <h4>What if we factor in International penalties?</h4>

          <p>International games don't go by seasons, but if you insist on factoring in internationals, then <strong className="text-messi">Messi's most penalties taken in a season is 18 in 2011/12</strong> (14 out of 17 for Barcelona and 1 from 1 for Argentina). These 15 penalty goals for club and country in 2011/12 are also the most Messi has scored in a single season.</p>
          
          <p><strong className="text-ronaldo">Ronaldo's highest number of penalty attempts for club and country in a season is 19</strong>, which was in 2019/20.</p>

          <p>This is also the most penalty goals Ronaldo has scored in a single season for club and country, with 17 penalties scored.</p>

          <h3>Most Penalties TAKEN in a Single Season</h3>

          <h4>League Only</h4>
          <ul>
            <li><strong className="text-messi">Messi: 11</strong> (2011/12)</li>
            <li><strong className="text-ronaldo">Ronaldo: 13</strong> (2011/12, 2019/20)</li>
          </ul>

          <h4>All Club Competitions</h4>
          <ul>
            <li><strong className="text-messi">Messi: 17</strong> (2011/12)</li>
            <li><strong className="text-ronaldo">Ronaldo: 16</strong> (2014/15, 2019/20)</li>
          </ul>

          <h4>Club + Country</h4>
          <ul>
            <li><strong className="text-messi">Messi: 18</strong> (2011/12) (17 Barcelona + 1 Argentina)</li>
            <li><strong className="text-ronaldo">Ronaldo: 19</strong> (2019/20) (16 Juventus + 3 Portugal)</li>
          </ul>

          <h3>Most Penalties SCORED in a Single Season</h3>

          <h4>League Only</h4>
          <ul>
            <li><strong className="text-messi">Messi: 10</strong> (2011/12)</li>
            <li><strong className="text-ronaldo">Ronaldo: 12</strong> (2011/12, 2019/20)</li>
          </ul>

          <h4>All Club Competitions</h4>
          <ul>
            <li><strong className="text-messi">Messi: 14</strong> (2011/12)</li>
            <li><strong className="text-ronaldo">Ronaldo: 14</strong> (2011/12, 2019/20)</li>
          </ul>

          <h4>Club + Country</h4>
          <ul>
            <li><strong className="text-messi">Messi: 15</strong> (2011/12) (14 Barcelona + 1 Argentina)</li>
            <li><strong className="text-ronaldo">Ronaldo: 17</strong> (2019/20) (14 Juvenuts + 3 Portugal)</li>
          </ul>

          <h3>More Penalty Stats</h3>
          <p>What do their goal tallies look like without penalties? To find out, check out our <Link to={`/detailed-stats/goals-without-penalties/`} className="text-highlight underline">Goals Without Penalties</Link> section.</p>

          <p>If you’re looking for any other penalty stats, hit us up on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we’ll do our best to help.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          hatTricks
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          hatTricks
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          pensMissed
          pens
        }
      }
    }
    allSheetMessiHatTricks {
      edges {
        node {
          goals
          pens
        }
      }
    }
    allSheetRonaldoHatTricks {
      edges {
        node {
          goals
          pens
        }
      }
    }
  }
`

export default PenaltiesPg
